import(/* webpackMode: "eager", webpackExports: ["CSPostHogProvider"] */ "/vercel/path0/app/analytics/providers.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["CustomProviders"] */ "/vercel/path0/components/CustomProviders.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/components/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeSwitcher"] */ "/vercel/path0/components/theme-switcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TooltipProvider"] */ "/vercel/path0/components/ui/tooltip.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.3.1_next@14.2.3_@babel+core@7.24.7_@opentelemetry+api@1.9.0_react-dom@18._dzzffsbpxi7cdtqnegcqywflhu/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.0.12_next@14.2.3_@babel+core@7.24.7_@opentelemetry+api@1.9.0_react-d_6e5ahxr2kvgydxsisbutreenga/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.7_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
