  // @ts-nocheck
  import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

  const initialState = {
    past: [],
    present: {
      data: [],
      status: 'idle',
      error: null,
    },
    future: [],
  };

  export const fetchCompanyData = createAsyncThunk(
    'companyData/fetchStatus',
    async ({ sqlQuery, prefix, rowLimit, encryptedSubscriptionStatus }, { rejectWithValue }) => {
      console.log("Sending request with:", { sqlQuery, prefix, rowLimit, encryptedSubscriptionStatus });
      try {
        const response = await fetch('/api/companyData', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ sqlQuery, prefix, rowLimit, encryptedSubscriptionStatus }),
        });
  
        if (!response.ok) {
          const errorResponse = await response.json();
          console.log("Error response:", errorResponse);
          return rejectWithValue(errorResponse);
        }
  
        const data = await response.json();
        console.log("Received data:", data);  
        console.log("Received data length:", data.length);
        

        return data;
        
      } catch (error) {
        console.error("Fetch error:", error);
        return rejectWithValue({
          message: error.message,
          code: error.code || 'UNKNOWN_ERROR',
          sqlState: error.sqlState || 'UNKNOWN_SQL_STATE',
          sqlMessage: error.sqlMessage || 'An unknown error occurred while querying the database',
        });
      }
    }
  ); 

  const companyDataSlice = createSlice({
    name: 'companyData',
    initialState,
    reducers: {
      undo: (state) => {
        if (state.past.length === 0) return;
        const previousState = state.past.pop();
        state.future.unshift({ ...state.present });
        state.present = previousState;
      },
      redo: (state) => {
        if (state.future.length > 0) {
          const nextState = state.future.shift();
          state.past.push({ ...state.present });
          state.present = nextState;
        }
      },
      clearData: (state) => {
        state.present.data = [];
        state.present.status = 'idle';
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchCompanyData.pending, (state) => {
          state.present.status = 'loading';
          state.present.error = null;
        })
        .addCase(fetchCompanyData.fulfilled, (state, action) => {
          state.past.push({ ...state.present });
          state.future = [];
          state.present.status = 'succeeded';
          state.present.data = action.payload;
        })
        .addCase(fetchCompanyData.rejected, (state, action) => {
          state.past.push({ ...state.present });
          state.future = [];
          state.present.status = 'failed';
          state.present.error = action.payload;
        });
    },
  });

  import { createSelector } from 'reselect';

  export const selectHasData = createSelector(
    (state) => state.companyData,
    (companyData) => {
      return (
        companyData.past.length > 0 ||
        companyData.present.data.length > 0 ||
        companyData.future.length > 0
      );
    }
  );

  export const { undo, redo, clearData } = companyDataSlice.actions;
  export default companyDataSlice.reducer;
