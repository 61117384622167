import { configureStore } from '@reduxjs/toolkit';

import companyDataReducer from '@/app/features/companyData/companyDataSlice';
// import userReducer from '@/app/features/userData/userSlice';

export const store = configureStore({
  reducer: {
    companyData: companyDataReducer
    // userData: userReducer
  },
});


